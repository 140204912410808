
import './Header.css';
function Header()
{
    return(

   <div>
    <div className='contact'>
      <h5 className='move1'>08373-266343,262075</h5>
      <h5 className='move2'>Fax:08373-266427</h5>
      <h5 className='move3'>CET CODE:E033</h5>
      <h5 className='move4'>Research Program</h5>
      <h5 className='move5'>Alumni</h5>
    </div>
</div>
);
}
export default Header;
